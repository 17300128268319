import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import {
  Card,
  Grid,
  Text,
  Button,
  Group,
  Paper,
  Container,
  Title,
} from '@mantine/core';
import useHttpClient from '../hooks/useHttpClient';
import { useInsuranceMemberContext } from './InsuranceMemberContext';
import PlanPdfUpload from './PlanPdfUpload';
import { notifications } from '@mantine/notifications';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useStepper } from './RegistrationSteppers';

const initialPriorities = [
  { id: '1', label: 'Low Premium' },
  { id: '2', label: 'Low Deductible' },
  { id: '3', label: 'Less Copay' },
  { id: '4', label: 'HSA Account' },
];

interface PrioritiesTabProps {
  onFieldsCompleteChange?: (isComplete: boolean) => void;
}

const PrioritiesTab: React.FC<PrioritiesTabProps> = ({
  onFieldsCompleteChange,
}) => {
  const [expanded, setExpanded] = useState(true);
  const { subscriberMemberId } = useInsuranceMemberContext();
  const [memberData, setMemberData] = useState<any>(null);
  const httpClient = useHttpClient();
  const [priorities, setPriorities] = useState(initialPriorities);
  const [selectedPlan, setSelectedPlan] = useState<number>(null);
  const { markStepAsDone } = useStepper();

  const [recommendation, setRecommendation] = useState('');

  const fetchMember = async () => {
    try {
      // Fetch member data
      const { data: memberData } = await httpClient.get(`/api/subscriber/`);
      setMemberData(memberData);
      setSelectedPlan(Number(memberData?.planid));

      // Fetch priorities
      const { data: prioritiesData } = await httpClient.get(
        `/api/subscriber/planpriorities`,
      );

      // Update priorities with fetched data
      if (Array.isArray(prioritiesData) && prioritiesData.length > 0) {
        const formattedPriorities = prioritiesData.map((item, index) => ({
          id: String(index + 1),
          label: item.priority,
        }));
        setPriorities(formattedPriorities);
      }
    } catch (error) {
      console.error('Error fetching member data:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to load member data',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const updatedPriorities = Array.from(priorities);
    const [reorderedItem] = updatedPriorities.splice(result.source.index, 1);
    updatedPriorities.splice(result.destination.index, 0, reorderedItem);

    const rankedPriorities = updatedPriorities.map((priority, index) => ({
      ...priority,
      rank: index + 1,
    }));

    setPriorities(rankedPriorities);

    try {
      await httpClient.post('/api/subscriber/planpriorities', {
        priorities: rankedPriorities,
      });
      setRecommendation('Thank you for telling us your priorities!');
    } catch (error) {
      console.error('Error updating priorities:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update priorities',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  const handleRecommendClick = async () => {
    try {
      await httpClient.post('/api/subscriber/planpriorities', { priorities });
      setRecommendation('Thank you for telling us your priorities!');
    } catch (error) {
      console.error('Error updating priorities:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update priorities',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  // Fetch member data on component mount
  useEffect(() => {
    if (subscriberMemberId) {
      fetchMember();
    }
  }, [subscriberMemberId]);

  useMemo(() => {
    if (selectedPlan) {
      markStepAsDone('plan-selection');
      onFieldsCompleteChange?.(true);
    }
  }, [selectedPlan]);

  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  const handlePlanSelect = async (planId: string) => {
    try {
      setSelectedPlan(Number(planId));
      const response = await httpClient.put(
        `/api/subscriber/plan/${subscriberMemberId}`,
        { planId },
      );
      console.log('Plan selected successfully:', response.data);

      notifications.show({
        title: 'Success',
        message: 'Plan selected successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error selecting plan:', error);
      notifications.show({
        title: 'Error',
        message: 'Error selecting plan',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  return (
    <Container size={'xxl'}>
      <Title order={1} style={{ textAlign: 'center' }}>
        Plan Priorities
      </Title>
      <br />

      {memberData && (
        <>
          <Group justify="space-between" mt="md">
            <Text fw={500} size="lg">
              Share your current plan
            </Text>
          </Group>
          <Paper shadow="md" p="lg" withBorder mt="md">
            <PlanPdfUpload
              planUploaded={memberData.planuploaded}
              subscriberMemberId={subscriberMemberId}
              enrollmentcode={memberData.enrollmentcode}
            />
          </Paper>
        </>
      )}
      <Group justify="space-between" mt="md">
        <Text fw={500} size="lg">
          Tell us your priorities
        </Text>
      </Group>
      <Paper shadow="md" p="lg" withBorder mt="md">
        <Text size="md" mb="sm">
          Drag and drop the options below to rank order what you are looking for
          in your health plan.
        </Text>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="priorities">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {priorities.map((priority, index) => (
                  <Draggable
                    key={priority.id}
                    draggableId={priority.id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          padding: '8px',
                          margin: '4px 0',
                          backgroundColor: '#f1f1f1',
                          color: '#555',
                          borderRadius: '4px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          cursor: 'grab',
                          userSelect: 'none',
                          ...provided.draggableProps.style,
                        }}
                      >
                        <span>{priority.label}</span>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Group>
          <Text>{recommendation}</Text>
        </Group>
      </Paper>
      {/* <Paper p="lg" withBorder mt="md">
        <Text size="md">
          Thank you for sharing your information! We are diligently working on
          creating a personalized plan tailored to your needs.
        </Text>
        <Text size="md">
          Your next step will be to select the best plan option for you. You
          will be notified by email your plan options are ready for you to
          review
        </Text>
      </Paper> */}
    </Container>
  );
};

export default PrioritiesTab;
