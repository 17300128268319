import React, { useState, useEffect } from 'react';
import { Container, TextInput, Button, Group, Grid } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useMemberContext } from './MemberContext';
import { Member } from '../types/members.types';
import DarkModeToggle from './DarkModeToggle';
import useHttpClient from './hooks/useHttpClient';
import { Title } from '@mantine/core';
import { Table } from '@mantine/core';
import '../App.css';
import ConfirmMemberDeleteModal from './ConfirmMemberDeleteModal';

const AdminPortal: React.FC = (props) => {
  const [members, setMembers] = useState<Member[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { setIsNewMember, setMemberId } = useMemberContext();
  const navigate = useNavigate();
  const httpClient = useHttpClient();
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);
  const [wecareMemberId, setWecareMemberId] = useState<number>(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const fetchMembers = async () => {
    try {
      const response = await httpClient.get<Member[]>('/api/members');
      setMembers(response.data);
    } catch (error) {
      console.error('Error fetching members', error);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredMembers = members
    .filter((member) =>
      `${member.firstname} ${member.lastname} ${member.npi}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
    )
    .sort((a, b) => {
      if (a.lastname > b.lastname) return 1;
      if (a.lastname < b.lastname) return -1;
      if (a.firstname > b.firstname) return 1;
      if (a.firstname < b.firstname) return -1;
      return 0;
    });

  const handleMemberClick = async (memberId: number) => {
    setIsNewMember(false);
    setMemberId(memberId);
    navigate(`/wecare/member/${memberId}`);
  };

  const handleAddNewMember = () => {
    setIsNewMember(true);
    setMemberId(0);
    navigate('/member');
  };

  const handleDeleteMemberButtonClick = (memberId: number) => {
    setWecareMemberId(memberId);
    setOpenDeleteConfirmationModal(true);
  };

  const handleSoftDeleteMember = async (memberId: number) => {
    console.log('Member ID to delete:', memberId);

    try {
      setIsDeleting(true);
      const response = await httpClient.delete(`/api/members/${memberId}`);

      if (response.status === 200) {
        // Update the UI by removing the deleted member from the state
        setMembers((prevMembers) =>
          prevMembers.filter((member) => member.id !== memberId),
        );
        setOpenDeleteConfirmationModal(false);
      }
    } catch (error) {
      console.error('Error deleting member', error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Container size="xl">
      <Title style={{ textAlign: 'left', marginBottom: '2rem' }}>
        Admin Portal
      </Title>
      <ConfirmMemberDeleteModal
        opened={openDeleteConfirmationModal}
        onClose={() => setOpenDeleteConfirmationModal(false)}
        onSubmit={() => handleSoftDeleteMember(wecareMemberId)}
        isDeleting={isDeleting}
      />

      <Grid justify="space-between">
        <Grid.Col span="auto">
          <DarkModeToggle />
        </Grid.Col>
      </Grid>
      <Group mb="md">
        <TextInput
          placeholder="Search members"
          value={searchTerm}
          onChange={handleSearch}
        />
        <Button onClick={handleAddNewMember}>Add New Member</Button>
      </Group>

      <Group>
        <Table className="ryze-list table-striped table-highlight">
          <thead style={{ textAlign: 'left' }}>
            <tr>
              <th>Name</th>
              <th>NPI</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredMembers.map((member) => (
              <tr key={member.id} onClick={() => handleMemberClick(member.id)}>
                <td>{`${member.firstname ?? ''} ${member.lastname ?? ''}`}</td>
                <td>{member.npi}</td>
                <td>
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDeleteMemberButtonClick(member.id);
                    }}
                    color={'red'}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Group>
    </Container>
  );
};

export default AdminPortal;
