import { useState, useMemo } from 'react';
import {
  Grid,
  Text,
  Paper,
  Container,
  Title,
  Timeline,
  Loader,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import useHttpClient from './hooks/useHttpClient';
import { useMantineColorScheme, useComputedColorScheme } from '@mantine/core';
import { useAuth } from './hooks/useAuth';
import { useAuth as useAuth2 } from './AuthProvider';
import Widget1 from './DashboardWidgets/Widget1';
import {
  IconBuildingBank,
  IconFriends,
  IconGitBranch,
  IconGitCommit,
  IconGitPullRequest,
  IconInbox,
  IconMessageDots,
  IconNotebook,
  IconUserCheck,
  IconCircleCheck,
  IconCircleX,
} from '@tabler/icons-react';
import { subMenuList } from './menu.config';
import { formatDistanceToNow } from 'date-fns';
import { Anchor } from '@mantine/core';

export default function Dashboard({ path }) {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme('light');
  const toggleColorScheme = () => {
    setColorScheme(computedColorScheme === 'dark' ? 'light' : 'dark');
  };

  const httpClient = useHttpClient();
  const navigate = useNavigate();
  const { organizationId, subscriberMemberId, wecareMemberId } = useAuth();
  const [userInfo, setUserInfo] = useState<any>();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<any>();

  const auth = useAuth2();
  const privileges = auth.getPrivileges();
  const username = privileges[0].username;
  const types = (privileges || [])
    .map((priv) => priv.type)
    .join(',')
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());

  const fetchUserInformation = async () => {
    console.log('subscriberMemberId', subscriberMemberId);
    setIsloading(true);
    try {
      if (subscriberMemberId) {
        const { data } = await httpClient.get(`/api/subscriber`);
        setUserInfo(data);
      }
      if (organizationId) {
        const { data } = await httpClient.get(`/api/organization`);
        setUserInfo((setUserInfo) => {
          return { ...setUserInfo, ...data };
        });
      }
      const { data } = await httpClient.get(`/api/user/metadata`);
      setMetadata(data.metadata);
    } catch (error) {
      console.error('failed to fetch user information', error);
    } finally {
      setIsloading(false);
    }
  };

  useMemo(() => {
    fetchUserInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberMemberId, organizationId]);

  const stepDetails = {
    // clinic protal
    'practice-info': {
      description: 'Practice Information',
      icon: <IconUserCheck size={20} />,
    },
    employees: {
      description: 'Manage Employees',
      icon: <IconFriends size={20} />,
    },
    'next-steps': {
      description: 'Next Steps',
      icon: <IconInbox size={20} />,
    },
    // member / employee
    'personal-info': {
      description: 'Provide your contact and basic details.',
      icon: <IconUserCheck size={20} />,
    },
    'family-member-management': {
      description: 'Add details of your immediate family members.',
      icon: <IconFriends size={20} />,
    },
    priorities: {
      description: 'Define your medical practice focus areas.',
      icon: <IconInbox size={20} />,
    },
    'plan-selection': {
      description: 'Choose a suitable membership plan.',
      icon: <IconNotebook size={20} />,
    },
    'payment-information': {
      description: 'Enter payment information to complete registration.',
      icon: <IconBuildingBank size={20} />,
    },
  };

  const timeline = {
    member: subMenuList?.independentPhysician.menu,
    clinic: subMenuList?.clinic.menu,
    employee: subMenuList?.employee.menu,
  }[path];

  return (
    <Container size="xl">
      <Paper withBorder shadow="none" p={0} radius="md">
        <Grid>
          <Grid.Col span={12} p="xl" style={{ position: 'relative' }}>
            {isLoading ? (
              <Container
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Loader />
              </Container>
            ) : (
              <>
                <Title order={3} mt="20" style={{ textAlign: 'left' }}>
                  Welcome Back {userInfo?.firstname}!
                </Title>
                {/* <Text
                  style={{
                    fontSize: '30px',
                    fontWeight: 600,
                    opacity: 0.1,
                    position: 'absolute',
                    width: 'calc(50% - 200px)',
                    bottom: '0px',
                    right: 20,
                    textAlign: 'right',
                  }}
                >
                  Health Insurance{' '}
                  <span style={{ fontWeight: 1000 }}>
                    built for independent physicians
                  </span>{' '}
                  to be the smarter healthcare choice
                </Text> */}
                {metadata?.stepsDone && (
                  <>
                    <Text size="sm" mt="20">
                      Click on the last active item below to continue where you
                      left off.
                    </Text>
                    <Timeline active={-1} bulletSize={28} lineWidth={2} mt="20">
                      {timeline.map((item, idx) => {
                        return (
                          <Timeline.Item
                            key={item.key}
                            bullet={
                              metadata?.stepsDone[idx] ? (
                                <IconCircleCheck
                                  style={{ color: 'blue' }}
                                  size={36}
                                />
                              ) : metadata?.stepsDone[idx] === false &&
                                metadata?.ts[idx] ? (
                                <IconCircleX
                                  style={{ color: 'red' }}
                                  size={36}
                                />
                              ) : (
                                stepDetails[item.key].icon
                              )
                            }
                            title={
                              metadata?.stepsDone[idx] === true ||
                              (metadata?.stepsDone[idx] === false &&
                                metadata?.ts[idx]) ? (
                                <Anchor href={`/insurance/${path}#` + item.key}>
                                  {item.value}
                                </Anchor>
                              ) : (
                                item.value
                              )
                            }
                          >
                            <Text c="dimmed" size="xs">
                              <i>{stepDetails[item.key].description}</i>
                            </Text>
                            <Text size="xs" mt={4}>
                              {metadata?.ts[idx] &&
                                'Updated ' +
                                  formatDistanceToNow(
                                    new Date(
                                      new Date(metadata?.ts[idx]).getTime(),
                                    ),
                                    {
                                      addSuffix: true,
                                    },
                                  )}
                            </Text>
                          </Timeline.Item>
                        );
                      })}
                    </Timeline>
                  </>
                )}
              </>
            )}
          </Grid.Col>
        </Grid>
        <br />
      </Paper>
    </Container>
  );
}
