import { Button, Group, Modal } from '@mantine/core';

interface ConfirmMemberDeleteModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isDeleting: boolean;
}

const ConfirmMemberDeleteModal = ({
  opened,
  onClose,
  onSubmit,
  isDeleting,
}: ConfirmMemberDeleteModalProps) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="DELETE MEMBER"
      centered
      closeOnClickOutside={!isDeleting}
      closeOnEscape={!isDeleting}
      withCloseButton={!isDeleting}
    >
      <p>Are you sure you want to delete this member?</p>
      <Group justify={'space-between'}>
        <Button disabled={isDeleting} onClick={onClose} mt="md" mr="xs">
          Cancel
        </Button>
        <Button disabled={isDeleting} loading={isDeleting} onClick={onSubmit} color="red" mt="md">
          Delete
        </Button>
      </Group>
    </Modal>
  );
};

export default ConfirmMemberDeleteModal;
