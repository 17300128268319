import React, { useState } from 'react';
import useHttpClient from '../hooks/useHttpClient';
import axios from 'axios';
import {
    Button,
    TextInput,
    Text,
    ThemeIcon,
    Group,
    Modal,
    Loader,
} from '@mantine/core';
import { Check } from 'tabler-icons-react';

interface EmployeeListUploadProps {
    employeesUploaded: boolean;
    practiceId: number;
    modalOpened: boolean;
    setModalOpened: (value: boolean) => void;
}

const EmployeeListUpload: React.FC<EmployeeListUploadProps> = ({
    employeesUploaded,
    practiceId,
    modalOpened,
    setModalOpened,
}) => {
    const [file, setFile] = useState<File | null>(null);
    const [message, setMessage] = useState('');
    const [isEmployeesUploaded, setIsEmployeesUploaded] =
        useState(employeesUploaded);
    const [isLoading, setIsLoading] = useState(false);
    const httpClient = useHttpClient();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (
            selectedFile &&
            ['text/csv', 'application/vnd.ms-excel', 'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                selectedFile.type
            )
        ) {
            setFile(selectedFile);
            setMessage('');
        } else {
            setMessage(
                'Please upload a valid file (CSV, Excel, or ODS formats only).'
            );
        }
    };

    const uploadFile = async () => {
        if (!file) {
            setMessage('Please select a file to upload.');
            return;
        }

        setIsLoading(true);

        try {
            const response = await httpClient.get(
                '/api/upload/generate-employee-list-url',
                {
                    params: { fileName: file.name, fileType: file.type },
                }
            );

            const { signedUrl } = response.data;
            await axios.put(signedUrl, file, {
                headers: {
                    'Content-Type': file.type,
                },
            });

            // Update the backend to mark the employees file as uploaded
            // await httpClient.put('/api/practices', {
            //     employeesUploaded: true,
            // });

            setMessage('File uploaded successfully');
            setIsEmployeesUploaded(true);
            setFile(null); // Clear the selected file
        } catch (error) {
            console.error('Error uploading file:', error);
            setMessage(
                error.response?.data?.message || 'File upload failed. Please try again.'
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleModalClose = () => {
        setModalOpened(false);
        if (isEmployeesUploaded) {
            setMessage('');
        }
    };

    return (
        <>
            <Modal
                opened={modalOpened}
                onClose={handleModalClose}
                title="Upload Employee List"
            >
                {isEmployeesUploaded ? (
                    <Group>
                        <ThemeIcon color="teal" radius="xl" size="lg">
                            <Check size={24} />
                        </ThemeIcon>
                        <Text color="teal" size="md">
                            Employee list uploaded successfully. Thank you!
                        </Text>
                    </Group>
                ) : (
                    <>
                        <Text size="md" mb="sm">
                            Only include employees in your file (no family members).
                            We encourage you to upload a csv (preferred), excel, or ods file formatted with the following
                            columns: <strong>firstname</strong>, <strong>lastname</strong>,{' '}
                            <strong>gender</strong>, <strong>birthdate</strong>, and{' '}
                            <strong>email</strong>. Please use <strong>M</strong> or{' '}
                            <strong>F</strong> for gender and format dates as follows:{' '}
                            <strong>MM/DD/YY</strong> (e.g., 08/02/60) or{' '}
                            <strong>YYYY-MM-DD</strong> (e.g., 1989-11-02).{' '}
                            <strong>
                                However, if you’re unable to follow this format, don’t worry—
                                upload the file you have, and we’ll work with it!
                            </strong>
                        </Text>
                        <TextInput
                            type="file"
                            onChange={handleFileChange}
                            accept=".csv, .xls, .xlsx, .ods"
                            label="Select file to upload"
                        />
                        <Button
                            onClick={uploadFile}
                            mt="sm"
                            color="blue"
                            disabled={isLoading}
                        >
                            {isLoading ? <Loader size="sm" /> : 'Upload File'}
                        </Button>
                        {message && <Text mt="sm">{message}</Text>}
                    </>
                )}
            </Modal>
        </>
    );
};

export default EmployeeListUpload;
