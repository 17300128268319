import { useState, useEffect } from 'react';
import {
  UnstyledButton,
  Tooltip,
  Text,
  rem,
  Avatar,
  Group,
  Flex,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import classes from './DoubleNavbar.module.css';
import { useAuth } from './AuthProvider';
import { User } from 'tabler-icons-react';

export function DoubleNavbar({ location, mainMenu = [], subMenu }) {
  // main menu
  const f = mainMenu.filter((menu) => menu.path == location.pathname)[0];
  const [active, setActive] = useState(f?.label || mainMenu?.[0]?.label);
  const navigate = useNavigate();
  const { logoutAuth } = useAuth();
  const auth = useAuth();
  const privileges = auth.getPrivileges();
  const username = privileges[0].username;
  const type = privileges[0].type
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());

  const mainLinks = mainMenu.map((link) => {
    if (link.requiredPrivileges?.length) {
      const privileges_ = privileges.map((p) => p.type);
      const hasAll = link.requiredPrivileges.every((item) =>
        privileges_.includes(item),
      );
      if (!hasAll) {
        return <></>;
      }
    }
    return (
      <Tooltip
        label={link.label}
        position="right"
        withArrow
        transitionProps={{ duration: 0 }}
        key={link.label}
      >
        <UnstyledButton
          onClick={() => {
            if (link.path === '/logout') {
              logoutAuth();
            } else {
              navigate(link.path || '/');
              setActive(link.label);
            }
          }}
          className={classes.mainLink}
          data-active={link.label === active || undefined}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <link.icon
            style={{ width: rem(30), height: rem(30), marginRight: rem(12) }}
            stroke={1.5}
          />
          <Text size="sm">{link.label}</Text>
        </UnstyledButton>
      </Tooltip>
    );
  });

  // sub menu
  const { hidden, menu, allowSwitchingBetweenPages = true } = subMenu;
  const hash = location.hash.substring(1);
  const [activeLink, setActiveLink] = useState(hash || menu?.[0]?.key);
  useEffect(() => {
    setActiveLink(hash);
  }, [hash, location.hash]);

  const links =
    !hidden &&
    menu?.map((link) => (
      <a
        className={
          allowSwitchingBetweenPages ? classes.link : classes.linkDisabled
        }
        data-active={
          activeLink === link.key ||
          (!activeLink && link.key === menu?.[0]?.key) ||
          undefined
        }
        href="#"
        onClick={(event) => {
          event.preventDefault();
          if (allowSwitchingBetweenPages) {
            setActiveLink(link.key);
            window.location.hash = link.key;
          }
        }}
        key={link.key}
      >
        {link.value}
      </a>
    ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.wrapper}>
        <div className={classes.aside}>{mainLinks}</div>
        {menu && <div className={classes.main}>{links}</div>}
      </div>
      <div className="classes.userInfo">
        {mainMenu?.length && (
          <Flex className={classes.userInfo} align="center">
            <Avatar radius="xl" mr={5}>
              <User size={24} />
            </Avatar>
            <div style={{ width: 'calc(100% - 50px)' }}>
              <Text size="sm">{type}</Text>
              <Text size="xs" color="dimmed" className={classes.username}>
                {username}
              </Text>
            </div>
          </Flex>
        )}
      </div>
    </nav>
  );
}
