import React, { useState, useEffect } from 'react';
import { useForm, Controller, FieldError } from 'react-hook-form';
import {
  Button,
  Container,
  Select,
  Group,
  Paper,
  Alert,
  Table,
  Input,
  Title,
  Text,
  Modal,
  Loader,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMemberContext } from './MemberContext';
import useHttpClient from './hooks/useHttpClient';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import { Url } from 'url';
import {
  DownloadAndRenderImage,
  DownloadAndRenderPDF,
} from './DownloadAndRender';
import { IconTrash } from '@tabler/icons-react';

interface FormProps {
  memberId: number;
}

const UploadedDocumentsForm: React.FC<FormProps> = ({ memberId }) => {
  const {
    control,
    handleSubmit,
    getValues,
    register,
    formState: { errors },
  } = useForm();
  //   const watchAllFields = watch();
  const [fetchedDocuments, setFetchedDocuments] = useState(null);
  const httpClient = useHttpClient();

  const [opened, setOpened] = useState(false);
  const [url, setUrl] = useState<any>(null);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOpenModal = (key) => {
    setUrl(process.env.REACT_APP_API_URL + 'api/upload/rawfile/' + key);
    setOpened(true);
  };

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const response = await httpClient.get(`/api/upload/document/${memberId}`);
      if (!response.status.toString().startsWith('2')) {
        // Checking for non-success status codes
        throw new Error('Failed to fetch uploaded documents');
      }

      setFetchedDocuments(response.data);
    } catch (error) {
      console.error('Error fetching document:', error);
      if (error.response && error.response.status === 404) {
        // reset({});
        return;
      }
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch uploaded documents',
        color: 'red',
        position: 'bottom-center',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!fetchedDocuments) fetchDocuments();
  }, [memberId]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', data.file[0]);
      formData.append('type', data.type);
      formData.append('memberid', String(memberId));
      const response = await httpClient.post(
        `/api/upload/document/${memberId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      fetchDocuments();

      notifications.show({
        title: 'Success',
        message: 'Document added successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit document',
        color: 'red',
        position: 'bottom-center',
      });
    }
    setLoading(false);
  };

  const handleDelete = async (key) => {
    setLoading(true);
    try {
      await httpClient.delete('/api/upload/document/' + key);
      notifications.show({
        title: 'Success',
        message: 'Document deleted successfully',
        color: 'green',
        position: 'bottom-center',
      });
      fetchDocuments();
    } catch (error) {
      console.error('Error delete file:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to delete document',
        color: 'red',
        position: 'bottom-center',
      });
    }
    setLoading(false);
  };

  const rows = fetchedDocuments?.map((e) => (
    <Table.Tr key={e.id}>
      <Table.Td>{e.documenttype || 'n/a'}</Table.Td>
      <Table.Td
        onClick={() => handleOpenModal(e.key)}
        style={{ cursor: 'pointer', color: 'blue' }}
      >
        {e.filename}
      </Table.Td>
      <Table.Td>
        {formatDistanceToNow(e.updated_at, {
          addSuffix: true,
        })}
      </Table.Td>{' '}
      <Table.Td>
        <a
          href="#uploadedDocuments"
          onClick={() => {
            if (!loading) handleDelete(e.key);
          }}
          style={{
            color: loading ? '#CCC' : 'red',
            textDecoration: 'none',
            fontWeight: 800,
          }}
        >
          <IconTrash size={20} />
        </a>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Container>
      <Paper shadow="xs" withBorder>
        <Text mb={20}>
          Upload your licenses, cerifications and malpractice insurance here:
        </Text>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          encType="multipart/form-data"
        >
          {!!rows?.length && (
            <>
              <Title order={3} mb={10}>
                Documents
              </Title>

              <Table mb={20}>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Document Type</Table.Th>
                    <Table.Th>Filename</Table.Th>
                    <Table.Th>Updated</Table.Th>
                    <Table.Th></Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{rows}</Table.Tbody>
              </Table>
              <hr />
            </>
          )}
          <div>
            <Title order={3} mb={10}>
              Upload Form
            </Title>

            <label>Document Type</label>
            <Controller
              name="type"
              control={control} // Ensure `control` is obtained from `useForm`
              rules={{ required: 'Type is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  placeholder="Select a document type"
                  data={[
                    'License',
                    'Certification',
                    'Malpractice Insurance',
                    'Others',
                  ]}
                  {...field}
                  value={field.value || ''}
                  onChange={(value) => field.onChange(value || '')}
                  error={error?.message}
                  mb={20}
                />
              )}
            />
            <label>
              Select a file{' '}
              <small style={{ color: '#888' }}>
                (supported formats are pdf/png/jpg)
              </small>
            </label>
            <Input
              type="file"
              {...register('file', {
                required: 'File is required',
                validate: {
                  validFileType: (value) => {
                    const file = value[0];
                    if (!file) return 'No file selected';

                    // Allowed MIME types
                    const allowedTypes = [
                      'application/pdf',
                      'image/png',
                      'image/jpeg',
                    ];

                    // Check if the file type is in the allowed list
                    if (allowedTypes.includes(file.type)) {
                      return true;
                    }

                    return 'Only PDF, PNG, or JPEG files are allowed';
                  },
                },
              })}
              accept=".pdf,image/png,image/jpeg" // Limit file types
            />
            {errors.file && (
              <p style={{ color: 'red' }}>
                {(errors.file as FieldError)?.message || 'Unknown error'}
              </p>
            )}
          </div>
          <Group mt="md">
            <Button type="submit" disabled={loading}>
              {loading && <Loader size="sm" mr={10} />} Upload File
            </Button>
          </Group>
        </form>
      </Paper>

      <Modal
        size="xl"
        radius="md"
        opened={opened}
        onClose={() => setOpened(false)}
        title={<Title order={3}>Preview</Title>}
      >
        {(url?.match(/\.pdf$/i) && <DownloadAndRenderPDF url={url} />) ||
          (url?.match(/\.jpg|.\png$/i) && (
            <DownloadAndRenderImage url={url} />
          )) || <p>Unsupported file type</p>}
      </Modal>
    </Container>
  );
};

export default UploadedDocumentsForm;
