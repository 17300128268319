import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Grid,
  Paper,
  Title,
  Input,
  Select,
  ActionIcon,
  Text,
  Flex,
  Loader,
  LoadingOverlay,
} from '@mantine/core';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import { STATES_ARRAY } from '../../types/members.types';
import useHttpClient from '../hooks/useHttpClient';
import { useOrganizationContext } from './OrganizationContext';
import { loadScript } from '../../helpers/loadScript';

interface PracticeInfoTabProps {
  onFormSubmit?: (data: any) => void;
  onFieldsCompleteChange?: (isComplete: boolean) => void;
}

declare global {
  interface Window {
    google: any;
  }
}

const PracticeInfoTab: React.FC<PracticeInfoTabProps> = ({
  onFormSubmit,
  onFieldsCompleteChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { isDirty },
  } = useForm();
  const [initialData, setInitialData] = useState<any>(null);
  const { organizationId } = useOrganizationContext();
  const httpClient = useHttpClient();
  const [loading, setLoading] = useState(true);

  const addressRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let autocomplete: google.maps.places.Autocomplete | null = null;

    const handlePlaceSelect = async () => {
      if (!autocomplete) return;

      const place = autocomplete.getPlace();

      // Reset fields before handling the selected place
      setValue('address', '');
      setValue('street1', '');
      setValue('street2', '');
      setValue('city', '');
      setValue('state', '');
      setValue('zip', '');
      setValue('county', '');

      if (!place.address_components) {
        alert('Please select a complete address with a street number.');
        return;
      }

      let hasStreetNumber = false;
      place.address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          hasStreetNumber = true;
        }
      });

      if (!hasStreetNumber) {
        alert('Please select an address that includes a street number.');
        return;
      }

      let streetNumber = '';
      let route = '';
      let street2 = '';
      let city = '';
      let state = '';
      let zipCode = '';
      let county = '';

      place.address_components.forEach((component) => {
        const types = component.types;
        if (types.includes('street_number')) streetNumber = component.long_name;
        if (types.includes('route')) route = component.long_name;
        if (types.includes('subpremise')) street2 = component.long_name;
        if (types.includes('locality')) city = component.long_name;
        else if (types.includes('sublocality')) city = component.long_name;
        if (types.includes('administrative_area_level_1'))
          state = component.short_name;
        if (types.includes('postal_code')) zipCode = component.long_name;
        if (types.includes('administrative_area_level_2'))
          county = component.long_name.replace(' County', '');
      });

      const street1Value = `${streetNumber} ${route}`.trim();

      // Update the form fields and manually trigger validation to mark the form dirty
      setValue('street1', street1Value, { shouldDirty: true });
      setValue('street2', street2, { shouldDirty: true });
      setValue('city', city, { shouldDirty: true });
      setValue('state', state, { shouldDirty: true });
      setValue('zip', zipCode, { shouldDirty: true });
      setValue('county', county, { shouldDirty: true });

      await trigger(); // Manually trigger form validation
    };

    const initAutocomplete = () => {
      if (addressRef.current && window.google) {
        autocomplete = new window.google.maps.places.Autocomplete(
          addressRef.current,
          {
            types: ['address'],
            componentRestrictions: { country: 'us' },
          },
        );
        autocomplete.addListener('place_changed', handlePlaceSelect);
      }
    };

    const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    loadScript(googleMapsScriptUrl)
      .then(initAutocomplete)
      .catch((err) => console.error('Failed to load Google Maps script:', err));

    return () => {
      if (autocomplete) {
        google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [setValue, trigger]);

  const checkFieldsComplete = async () => {
    const requiredFields = [
      'name',
      'tin',
      'npi',
      'street1',
      'city',
      'state',
      'zip',
      'county',
      'adminfirstname',
      'adminlastname',
    ];
    const incompleteFields: string[] = [];
    const completeFields: string[] = [];

    return requiredFields.every((field) => {
      const isFieldComplete =
        initialData &&
        initialData[field as keyof typeof initialData] &&
        initialData[field as keyof typeof initialData] !== '';
      if (isFieldComplete) {
        completeFields.push(field);
      } else {
        incompleteFields.push(field);
      }

      // Log both the complete and incomplete fields for debugging purposes
      console.log('Complete Fields:', completeFields);
      console.log('Incomplete Fields:', incompleteFields);

      return isFieldComplete;
    });
  };

  const fetchPracticeInfo = async () => {
    try {
      const response = await httpClient.get(`/api/organization`);
      setInitialData(response.data);

      // validate data here
      if (checkFieldsComplete()) {
        onFieldsCompleteChange(true);
      }

      reset(response.data); // Initialize form with fetched data
    } catch (error) {
      console.error('Error fetching practice info:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch practice info',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (organizationId) {
      fetchPracticeInfo();
    }
  }, [organizationId]);

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      delete data.address;
      await httpClient.put(`/api/organization`, data);

      if (onFormSubmit) {
        onFormSubmit(data);
      }

      notifications.show({
        title: 'Success',
        message: 'Practice info updated successfully',
        color: 'green',
        position: 'bottom-center',
      });

      onFieldsCompleteChange(true);

      setIsEditing(false);
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update practice info',
        color: 'red',
        position: 'bottom-center',
      });
    }
    setLoading(false);
  };

  return (
    <Container>
      <Title order={1} style={{ textAlign: 'center' }}>
        Practice Information
      </Title>
      <br />

      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Title order={3}>Practice Info</Title>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Grid justify="space-between" align="center">
            <Grid.Col span={10}>
              <Title order={4}>Organization Details</Title>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Business Name"
                required
                {...register('name', { required: true })}
              // disabled={!isEditing}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Practice Website"
                {...register('practicewebsite')}
              // disabled={!isEditing}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="tin"
                control={control}
                rules={{ required: 'TIN is required' }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Input.Wrapper label="Tax Identification Number" required>
                      <Input
                        component={IMaskInput}
                        {...field}
                        mask="00-0000000" // TIN format
                        placeholder="XX-XXXXXXX"
                        disabled={loading}
                      />
                    </Input.Wrapper>
                    {error && (
                      <span style={{ color: 'red', fontSize: '0.8em' }}>
                        {error.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Type 2 Organization NPI"
                maxLength={10}
                {...register('npi')}
                // disabled={!isEditing}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                }}
              />
            </Grid.Col>
          </Grid>
        </Paper>
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Address Information</Title>

          <TextInput
            label={
              '\u0410ddress - Start typing your address below and pick one from the dropdown'
            }
            ref={addressRef}
            placeholder="Start by typing here"
          />
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label={'\u0405treet 1'}
                {...register('street1')}
                disabled
                required
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label={'\u0405treet 2'} {...register('street2')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="City"
                {...register('city')}
                disabled
                readOnly
                required
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="State"
                    data={STATES_ARRAY}
                    clearable
                    required
                    searchable
                    disabled
                    readOnly
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="ZIP"
                required
                {...register('zip')}
                disabled
                readOnly
                maxLength={5}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="County"
                required
                {...register('county')}
                disabled
                readOnly
              />
            </Grid.Col>
          </Grid>
        </Paper>
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={4}>Administrator Information</Title>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Administrator First Name"
                required
                {...register('adminfirstname', { required: true })}
                disabled={!isEditing}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Administrator Last Name"
                required
                {...register('adminlastname', { required: true })}
                disabled={!isEditing}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="adminphone"
                control={control}
                render={({ field }) => (
                  <Input.Wrapper label="Administrator Phone">
                    <Input
                      component={IMaskInput}
                      {...field}
                      mask="(000) 000-0000"
                      placeholder="(XXX) XXX-XXXX"
                      disabled={!isEditing}
                    />
                  </Input.Wrapper>
                )}
              />
            </Grid.Col>
          </Grid>
        </Paper>
        <Flex justify="flex-end" mr="2">
          <Button type="submit" mt="md" disabled={!isDirty || loading}>
            {loading ? (
              <>
                <Loader color="blue" size="xs" mr="xs" />
                Loading...
              </>
            ) : (
              'Save Changes'
            )}
          </Button>
        </Flex>
      </form>
    </Container>
  );
};

export default PracticeInfoTab;
