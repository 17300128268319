import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Grid,
  Paper,
  Title,
  ActionIcon,
  Select,
  NavLink,
  Flex,
  Loader,
  Table,
  Text,
  Modal,
  Divider,
  Input,
  InputBase,
  Checkbox,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useInsuranceMemberContext } from './InsuranceMemberContext';
import useHttpClient from '../hooks/useHttpClient';
import './css/FamilyManagementTab.css';
import { OrganizationMember } from '../../types/subscribermember.types';
import {
  IconUser,
  IconPlus,
  IconUsersGroup,
  IconUserShield,
  IconClock,
  IconArchiveOff,
} from '@tabler/icons-react';
import CustomDateInput from '../CustomDateInput';
import { Divide, Trash } from 'tabler-icons-react';
import { useStepper } from './RegistrationSteppers';
import { modals } from '@mantine/modals';
import { IMaskInput } from 'react-imask';
import { text } from 'stream/consumers';

interface ExtendedOrganizationMember extends OrganizationMember {
  memberId?: number;
}

interface FamilyManagementTabProps {
  isEmployeePortal?: boolean;
  onFieldsCompleteChange?: (isComplete: boolean) => void;
}

export const familyMgmtValidation = async () => {
  return await new Promise<boolean>((resolve) => {
    // do not prompt again if confirmed once
    try {
      const userMetadata = JSON.parse(localStorage.getItem('userMetadata'));
      if (userMetadata?.stepsDone[1] === true) {
        resolve(true);
        return;
      }
    } catch (e) {
      console.log(e);
    }
    modals.openConfirmModal({
      title: <h2>Confirm Action</h2>,
      children: 'I have entered all my family and dependents',
      centered: true,
      labels: { confirm: 'Yes', cancel: 'No' },
      padding: 0,
      styles: {
        content: { padding: '0px !important', paddingBottom: '0px !important' },
      },
      onConfirm: () => {
        resolve(true);
      },
      onCancel: () => resolve(false),
    });
  });
};

const FamilyManagementTab: React.FC<FamilyManagementTabProps> = ({
  isEmployeePortal,
  onFieldsCompleteChange,
}) => {
  const { subscriberMemberId, member, refreshMemberData } =
    useInsuranceMemberContext();
  const [selectedMemberId, setSelectedMemberId] = useState<number | null>(null);
  const [noDependents, setNodeDependents] = useState<boolean>(
    member.nodependents,
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const httpClient = useHttpClient();
  const { setEnabled } = useStepper();
  const [filter, setFilter] = useState<string>(null);
  let [members, setMembers] = useState<ExtendedOrganizationMember[]>([]);

  setEnabled(!isEditing);

  const { control, register, handleSubmit, reset, watch, setValue } =
    useForm<ExtendedOrganizationMember>();

  const fetchMembers = async () => {
    try {
      const response = await httpClient.get(`/api/subscriber/family`);
      const fetchedMembers: ExtendedOrganizationMember[] = response.data || [];

      const sortedMembers = fetchedMembers
        .filter((member) => member.enrollmentstatus !== 'Removed')
        .sort((a, b) => {
          const relationshipOrder = ['Primary', 'Spouse', 'Child'];
          return (
            relationshipOrder.indexOf(a.subscriberrelationship) -
            relationshipOrder.indexOf(b.subscriberrelationship)
          );
        });

      setMembers(sortedMembers);

      if (selectedMemberId !== null) {
        const selectedMember = sortedMembers.find(
          (member) => (member.id || member.memberId) === selectedMemberId,
        );
        if (selectedMember) {
          reset(selectedMember);
        }
      }

      checkFieldsComplete(sortedMembers);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch family members',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  const handleAddFamilyMember = () => {
    if (!member?.organizationid) {
      notifications.show({
        title: 'Error',
        message: 'Organization ID is missing.',
        color: 'red',
        position: 'bottom-center',
      });
      return;
    }

    const newMemberId = Date.now(); // Simple unique ID
    const newMember: ExtendedOrganizationMember = {
      memberId: newMemberId,
      firstname: '',
      lastname: '',
      birthdate: null,
      ssn: '',
      organizationid: member.organizationid,
      primarysubscriber: subscriberMemberId,
      subscriberrelationship: null,
      sex: null,
    };

    setMembers([...members, newMember]);
    setSelectedMemberId(newMemberId);
    setIsEditing(true);
    reset(newMember); // Reset form values explicitly to new member
  };

  const cancel = (e?: React.MouseEvent) => {
    if (isSubmitting) return;
    fetchMembers();
    setIsEditing((prev) => !prev);
  };

  const handleRemove = async (member: ExtendedOrganizationMember) => {
    setIsSubmitting(true);
    //Unsaved Member
    if (member.memberId) {
      setMembers(members.filter((m) => m.memberId !== member.memberId));
      if (selectedMemberId === member.memberId) {
        //Unfocus deleted member
        setSelectedMemberId(null);
        setIsEditing(false);
      }
    } else {
      //Member is saved delete from database
      const memberId = member.id;

      if (selectedMemberId === memberId) {
        //Unfocus deleted member
        setSelectedMemberId(null);
      }
      try {
        await httpClient.delete(`/api/subscriber/removemember/${memberId}`);
        notifications.show({
          title: 'Success',
          message: 'Family member removed successfully',
          color: 'green',
          position: 'bottom-center',
        });
        fetchMembers(); // Refresh the family member list after removal
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to remove family member',
          color: 'red',
          position: 'bottom-center',
        });
      }
    }
    setIsSubmitting(false);
  };

  const handleSetFilter = (filterText) => {
    setFilter(filterText);
  };

  const onSubmit = async (data: ExtendedOrganizationMember) => {
    try {
      // Check for multiple spouses
      const spouses = members.filter(
        (member) => member.subscriberrelationship === 'Spouse',
      );

      // If editing a spouse, ensure it is considered in the validation
      if (
        data.subscriberrelationship === 'Spouse' &&
        !spouses.some(
          (spouse) =>
            spouse.id === data.id || spouse.memberId === data.memberId,
        )
      ) {
        spouses.push(data);
      }

      if (spouses.length > 1) {
        notifications.show({
          title: 'Validation Error',
          message: 'A person cannot have more than one spouse.',
          color: 'red',
          position: 'bottom-center',
        });
        return; // Prevent submission
      }

      setIsSubmitting(true);

      const index = members.findIndex(
        (field) => (field.id || field.memberId) === selectedMemberId,
      );
      if (index !== -1) {
        let updatedFields = [...members];

        if (data.id) {
          // Update existing member
          await httpClient.put(`/api/subscriber/${data.id}`, data);
          updatedFields[index] = data;
        } else {
          // Create new member
          const { memberId, ...dataWithoutMemberId } = data;
          const response = await httpClient.post(
            `/api/subscriber/family`,
            dataWithoutMemberId,
          );
          const newMember = response.data; // Assuming it returns the new member with id
          updatedFields[index] = newMember;
          setSelectedMemberId(newMember.id);
        }

        setMembers(updatedFields);
        fetchMembers();
        setIsEditing(false);

        notifications.show({
          title: 'Success',
          message: 'Member saved successfully',
          color: 'green',
          position: 'bottom-center',
        });
      }
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to submit member',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkFieldsComplete = (members: ExtendedOrganizationMember[]) => {
    const requiredFields = [
      'firstname',
      'lastname',
      'birthdate',
      'sex',
      'subscriberrelationship',
    ];

    const allComplete = members.every((member) =>
      requiredFields.every(
        (field) =>
          (member[field as keyof OrganizationMember] &&
            member[field as keyof OrganizationMember] !== '') ||
          false,
      ),
    );
  };

  useEffect(() => {
    if (subscriberMemberId) {
      fetchMembers();
    }
  }, [subscriberMemberId]);

  useEffect(() => {
    if (selectedMemberId !== null) {
      const selectedMember = members.find(
        (field) => (field.id || field.memberId) === selectedMemberId,
      );
      if (selectedMember) {
        reset(selectedMember);
      }
    }
  }, [selectedMemberId, reset, members]);

  const handleNoDependents = async (event) => {
    setIsSubmitting(true);
    const noDependents = event.currentTarget.checked;
    try {
      await httpClient.put(`/api/subscriber/${member.id}`, {
        nodependents: noDependents,
      });
      setNodeDependents(noDependents);
      await refreshMemberData();
    } catch (e) {
      notifications.show({
        title: 'Error',
        message: 'Failed to update record',
        color: 'red',
        position: 'top-center',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  members = members.filter((member) => {
    if (filter) {
      return member.enrollmentstatus === filter;
    } else {
      return member.enrollmentstatus !== 'Removed';
    }
  });

  useEffect(() => {
    if (onFieldsCompleteChange && members.length > 0) {
      onFieldsCompleteChange(true);
    } else if (member.nodependents === true) {
      onFieldsCompleteChange(true);
    } else {
      onFieldsCompleteChange(false);
    }
  }, [member, members, onFieldsCompleteChange]);

  return (
    <Container>
      <Title order={1} style={{ textAlign: 'center' }}>
        Family Member Management
      </Title>
      <br />

      <Grid>
        <Grid.Col span={4}>
          <Paper withBorder shadow="xs" p="md">
            <IconUserShield size="64" />
            <Title order={3} mb={10}>
              Family and Dependents
            </Title>
            <Text m={0} size="sm">
              Use this area to enter your family and dependents
            </Text>
            <Divider m={20} />

            <NavLink
              onClick={() => {
                handleAddFamilyMember();
              }}
              label={
                <>
                  <Text color="blue">
                    <IconPlus size="16" /> Add Member
                  </Text>
                </>
              }
            />
            {/* <NavLink
              onClick={() => {
                handleSetFilter(null);
              }}
              label={
                <>
                  <Text color="blue">
                    <IconUser size="16" /> Manage Users
                  </Text>
                </>
              }
            /> */}
            {/* <NavLink
              onClick={() => handleSetFilter('New')}
              label={
                <>
                  <Text color="blue">
                    <IconClock size="16" /> Users awaiting approval
                  </Text>
                </>
              }
            /> */}
          </Paper>
        </Grid.Col>
        <Grid.Col span={8}>
          <Paper withBorder shadow="xs" p="md">
            {members?.length > 0 && (
              <Table highlightOnHover>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th style={{ width: '50%' }}>Name</Table.Th>
                    {/* <Table.Th>Status</Table.Th> */}
                    <Table.Th>Relationship</Table.Th>
                    <Table.Th style={{ width: '0%' }}>Action</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {members.map((member) => {
                    const memberKey = member.id || member.memberId;
                    return (
                      <Table.Tr
                        style={{ cursor: 'pointer', height: '45px' }}
                        key={memberKey}
                        onClick={() => {
                          if (!isEditing) {
                            setSelectedMemberId(memberKey);
                            setIsEditing(true);
                          }
                        }}
                      >
                        <Table.Td>
                          <IconUser
                            size="1rem"
                            stroke={1.5}
                            style={{ marginRight: '10px' }}
                          />
                          {member.firstname + ' ' + member.lastname}
                        </Table.Td>
                        {/* <Table.Td>{member.enrollmentstatus}</Table.Td> */}
                        <Table.Td>{member.subscriberrelationship}</Table.Td>
                        <Table.Td>
                          {(!!member.primarysubscriber || member.memberId) && (
                            <ActionIcon
                              color="red"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemove(member);
                              }}
                              variant="transparent"
                              style={{ float: 'right' }}
                              disabled={isSubmitting}
                            >
                              <Trash size={16} />
                            </ActionIcon>
                          )}
                        </Table.Td>
                      </Table.Tr>
                    );
                  })}
                </Table.Tbody>
              </Table>
            )}
            {members?.length === 0 && (
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'block',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '245px',
                    color: '#CCC',
                    padding: '40px',
                    position: 'relative',
                  }}
                >
                  <IconArchiveOff size="64" />
                  <Title order={2}>No Family Members</Title>
                  <Title order={5}>Click [+ Add Member] to add a member</Title>
                  {isSubmitting && <Loader size={24} pt={'lg'} color="#CCC" />}
                </div>

                <Checkbox
                  checked={noDependents}
                  disabled={isSubmitting}
                  onChange={handleNoDependents}
                  label={<Text>I have no spouse or dependents </Text>}
                />
              </div>
            )}
          </Paper>
        </Grid.Col>
      </Grid>

      <Modal
        opened={isEditing}
        onClose={() => {
          cancel();
        }}
        size="xl"
        title={<h2 style={{ margin: 0 }}>Add New Family Member</h2>}
      >
        {selectedMemberId !== null && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            <Grid justify="space-between" align="center">
              <Grid.Col span={11}>
                <Title order={3}>
                  {watch('firstname')} {watch('lastname')}
                </Title>
              </Grid.Col>
            </Grid>
            <Paper withBorder shadow="xs" p="md" mt="md">
              <Title order={4}>Basic Information</Title>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="First Name"
                    {...register('firstname', { required: true })}
                    disabled={!isEditing}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Last Name"
                    {...register('lastname', { required: true })}
                    disabled={!isEditing}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  {!watch('primarysubscriber') ? (
                    <TextInput
                      label="Relation"
                      value="Primary"
                      required
                      disabled
                    />
                  ) : (
                    <Controller
                      name="subscriberrelationship"
                      control={control}
                      rules={{ required: 'Relation is required' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="Relation"
                          data={[
                            { value: 'Spouse', label: 'Spouse' },
                            { value: 'Child', label: 'Child' },
                          ]}
                          disabled={!isEditing}
                          clearable
                          required
                        />
                      )}
                    />
                  )}
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="sex"
                    control={control}
                    rules={{ required: 'Sex is required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Sex at Birth"
                        data={[
                          { value: 'M', label: 'M' },
                          { value: 'F', label: 'F' },
                        ]}
                        clearable
                        disabled={!isEditing}
                        required
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <CustomDateInput
                    control={control}
                    name="birthdate"
                    label="Date of Birth"
                    disabled={!isEditing}
                    preventFutureDates
                  />
                </Grid.Col>
                {/* <Grid.Col span={6}>
                  <Controller
                    name="ssn"
                    control={control}
                    rules={{
                      required: 'SSN is required',
                      pattern: {
                        value: /^\d{3}-\d{2}-\d{4}$/,
                        message: 'Invalid SSN format',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <InputBase
                          label="SSN"
                          placeholder="XXX-XX-XXXX"
                          required
                          component={IMaskInput}
                          mask="000-00-0000"
                          disabled={!isEditing}
                          {...field}
                        />
                        {error && (
                          <span style={{ color: 'red', fontSize: '0.8em' }}>
                            {error.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Grid.Col> */}
              </Grid>
              {isEditing && (
                <Flex justify="flex-end">
                  <Button
                    disabled={isSubmitting}
                    color="red"
                    mt="md"
                    style={{ marginRight: '5px' }}
                    onClick={cancel}
                  >
                    Cancel
                  </Button>
                  <Button disabled={isSubmitting} type="submit" mt="md">
                    {isSubmitting ? (
                      <>
                        <Loader color="blue" size="xs" mr="xs" />
                        Loading...
                      </>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Flex>
              )}
            </Paper>
          </form>
        )}
      </Modal>
    </Container>
  );
};

export default FamilyManagementTab;
