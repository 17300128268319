import React, { useState, useRef, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export function DownloadAndRenderImage({ url }) {
  return <img src={url} alt="Downloaded Content" style={{ width: '100%' }} />;
}

export function DownloadAndRenderPDF({ url }) {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  return (
    <div style={{ border: '1px solid #CCC', textAlign: 'center' }}>
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        renderMode="canvas"
      >
        <Page
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </div>
  );
}
