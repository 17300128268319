import React, { useEffect, useState } from 'react';
import { Container, Table, Loader, Text, Title } from '@mantine/core';
import axios from 'axios';
import useHttpClient from '../hooks/useHttpClient';

const MonthlyPremium = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const httpClient = useHttpClient();

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await httpClient.get('/api/payment/monthlypremium'); // Replace with your API endpoint
        setData(response.data);
      } catch (err) {
        setError('Failed to load data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Container>
        <Loader size="xl" variant="dots" />
        <Text>Loading data...</Text>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Title order={3}>Monthly Premium</Title>
        <Text color="red" size="lg">
          {error}
        </Text>
      </Container>
    );
  }

  const headers = Object.keys(data[0] || {});
  const transposedData = headers.map((key) => ({
    key,
    values: data.map((row) => row[key]),
  }));

  return (
    <Container>
      <Text size="xl" mb="lg">
        Monthly Premium Data
      </Text>

      <Table striped highlightOnHover withTableBorder withColumnBorders>
        <thead>
          <tr>
            {/* <th>ID</th> */}
            <th>Period</th>
            <th>Enrolled Members</th>
            <th>Plans Count</th>
            <th>Final Premium</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              {/* <td>{item.id}</td> */}
              <td style={{ textAlign: 'center' }}>
                {item.year} - {item.month}
              </td>
              <td style={{ textAlign: 'center' }}>
                {item.enrolledmembers.length}
              </td>
              <td style={{ textAlign: 'center' }}>
                {Object.keys(item.planscount).map((i) => {
                  return (
                    <>
                      {i !== 'null' ? (
                        <>
                          <span style={{ color: 'blue' }}>{`Plan ${i}: `}</span>
                          {item.planscount[i]}
                        </>
                      ) : (
                        <>
                          <span style={{ color: 'blue' }}>{`Pending: `}</span>
                          {item.planscount[i]}
                        </>
                      )}
                      <br />
                    </>
                  );
                })}
              </td>
              <td style={{ textAlign: 'center' }}>
                {item.finalpremium ?? 'N/A'}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default MonthlyPremium;
