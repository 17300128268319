import React, { useEffect, useState } from 'react';
import { Tabs, Grid } from '@mantine/core';
import MemberForm from './MemberForm';
import HospitalForm from './HospitalForm';
import PracticeForm from './PracticeForm';
import CredentialingContactForm from './CredentialingContactForm';
import SpecialtyForm from './SpecialtyForm';
import LiabilityForm from './LiabilityForm';
import EmploymentForm from './EmploymentForm';
import ReferenceForm from './ReferenceForm';
import CertificationForm from './CertificationForm';
import EducationTrainingForm from './EducationTrainingForm';
import DisclosureForm from './DisclosureForm';
import ProfessionalIDsForm from './ProfessionalIDsForm';
import {
  useWecareMemberContext,
  WecareMemberProvider,
} from './WecareMemberContext';
import HomeIcon from './HomeIcon';
import LogoutIcon from './LogoutIcon';
import DarkModeToggle from './DarkModeToggle';
import { Member } from '../types/members.types';
import useHttpClient from './hooks/useHttpClient';
import { Title, Container } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import { subMenuList } from './menu.config';
import UploadedDocumentsForm from './UploadedDocumentsForm';

const WecareMemberPortal: React.FC = () => {
  const location = useLocation();
  const currentTab = location.hash.substring(1) || 'personal-info';
  const { isNewMember, memberId, setMemberId, setIsNewMember } =
    useWecareMemberContext();
  const [member, setMember] = useState<Member | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(currentTab);
  const httpClient = useHttpClient();
  const tabTitle = subMenuList.member.menu.find((layout) => {
    return layout.key === activeTab;
  })?.value;

  const fetchMember = async (id: number) => {
    try {
      const response = await httpClient.get(`/api/members/${id}`);
      const data = await response.data;
      setMember(data);
      setMemberId(id);
    } catch (error) {
      console.error('Error fetching member', error);
    }
  };

  useEffect(() => {
    if (!isNewMember && memberId !== 0) {
      fetchMember(memberId);
    }
  }, [memberId, isNewMember]);

  const handleMemberCreated = (newMemberId: number) => {
    setIsNewMember(false);
    setMemberId(newMemberId);
  };

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  if (!isNewMember && memberId === 0) {
    return null;
  }

  return (
    <Container size="xl">
      <Grid justify="space-between">
        <Grid.Col span="auto">
          <HomeIcon />
          <LogoutIcon />
        </Grid.Col>
        <Grid.Col span="auto">
          <DarkModeToggle />
        </Grid.Col>
        {/* <Grid.Col span="auto">
          <Button
            component="a"
            href="https://www.ryzehealth.com/join"
            target="_blank"
            rel="noopener noreferrer"
          >
            Signup for Ryze
          </Button>
        </Grid.Col> */}
      </Grid>

      <Title style={{ textAlign: 'center', marginBottom: '2rem' }}>
        {tabTitle}
      </Title>

      <Tabs value={activeTab}>
        <Tabs.Panel value="personal-info">
          {(isNewMember || member) && (
            <MemberForm
              member={member}
              setMember={setMember}
              memberId={memberId}
              onMemberCreated={handleMemberCreated}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="hospitals">
          <HospitalForm memberId={memberId} />
        </Tabs.Panel>
        <Tabs.Panel value="practices">
          <PracticeForm memberId={memberId} />
        </Tabs.Panel>
        <Tabs.Panel value="credentialingContacts">
          <CredentialingContactForm memberId={memberId} />
        </Tabs.Panel>
        <Tabs.Panel value="specialties">
          <SpecialtyForm memberId={memberId} />
        </Tabs.Panel>
        <Tabs.Panel value="liabilities">
          <LiabilityForm memberId={memberId} />
        </Tabs.Panel>
        <Tabs.Panel value="employment">
          <EmploymentForm memberId={memberId} />
        </Tabs.Panel>
        <Tabs.Panel value="references">
          <ReferenceForm memberId={memberId} />
        </Tabs.Panel>
        <Tabs.Panel value="certifications">
          <CertificationForm memberId={memberId} />
        </Tabs.Panel>
        <Tabs.Panel value="professionalIds">
          <ProfessionalIDsForm memberId={memberId} />
        </Tabs.Panel>
        <Tabs.Panel value="education">
          <EducationTrainingForm memberId={memberId} />
        </Tabs.Panel>
        <Tabs.Panel value="disclosures">
          <DisclosureForm memberId={memberId} />
        </Tabs.Panel>
        <Tabs.Panel value="uploadedDocuments">
          <UploadedDocumentsForm memberId={memberId} />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};

const WecareMemberPortalWrapped: React.FC = () => {
  return (
    <WecareMemberProvider>
      <Container size="xl" style={{ marginTop: '2rem' }}>
        <WecareMemberPortal />
      </Container>
    </WecareMemberProvider>
  );
};

export default WecareMemberPortalWrapped;
