import React, { useState } from 'react';
import { useAuth } from '../AuthProvider';
import {
  TextInput,
  PasswordInput,
  Button,
  Paper,
  Text,
  Anchor,
  Container,
  Title,
  Center,
  Alert,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import WecareLogo from '../WecareLogo';
import { IconAlertCircle } from '@tabler/icons-react';

export default function WecareLogin() {
  const [input, setInput] = useState({
    username: '',
    password: '',
  });
  const auth = useAuth();
  const [error, setError] = useState<void | Error>();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.username !== '' && input.password !== '') {
      const error = await auth.loginAuth(input, 'wecare');
      setError(error);
      return;
    }
    alert('Please provide a valid input');
  };

  return (
    <Container size={420} mt={100}>
      <Paper withBorder shadow="md" p={30} radius="md" mt="lg">
        <form onSubmit={handleSubmit}>
          <Center mb="lg">
            <WecareLogo />
          </Center>
          <Center mb="lg">
            <Title order={2}>Wecare Login</Title>
          </Center>
          {error && (
            <Alert
              variant="light"
              color="red"
              radius="xs"
              withCloseButton
              onClose={() => setError()}
              title="Incorrect email or password"
              icon={<IconAlertCircle />}
              mb="md"
            >
              Double check you entered your email and password correctly. If you forgot your password, you can reset it using the button below.
            </Alert>
          )}
          <TextInput
            label="Email"
            placeholder="Enter the email you signed up with"
            value={input.username}
            onChange={(e) => setInput({ ...input, username: e.target.value })}
            required
            mb="md"
          />
          <PasswordInput
            label="Password"
            placeholder="Enter your password"
            value={input.password}
            onChange={(e) => setInput({ ...input, password: e.target.value })}
            required
            mb="md"
          />
          <Center mt="md">
            <Button type="submit">Login</Button>
          </Center>
          <Center mt="md">
            <Text size="md">
              Forgot your password?{' '}
              <Anchor component={Link} to="/reset-password">
                Reset here
              </Anchor>
            </Text>
          </Center>
        </form>
      </Paper>
    </Container>
  );
}
