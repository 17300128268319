import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Grid,
  Paper,
  Title,
  Text,
  PasswordInput,
  Textarea,
  Loader,
} from '@mantine/core';
import { STATES_ARRAY } from '../types/members.types';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { useNavigate } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import CustomDateInput from './CustomDateInput';

interface IpaMemberSignupProps {
  initialData?: any;
  onFormSubmit?: (data: any) => void;
}

const IpaMemberSignup: React.FC<IpaMemberSignupProps> = ({
  initialData,
  onFormSubmit,
}) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: initialData,
  });
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const [loading, setLoading] = useState(false);

  const httpClient = useHttpClient();
  const navigate = useNavigate();

  const password = watch('password'); // Watch password for confirmation validation

  const onSubmit = async (data: any) => {
    if (passwordStrengthScore < 3) {
      notifications.show({
        title: 'Weak Password',
        message:
          'Please choose a stronger password (at least "Good" strength).',
        color: 'red',
        position: 'bottom-center',
      });
      return;
    }

    // Cast email to lowercase and remove password confirmation before submission
    const submissionData = {
      ...data,
      email: data.email.toLowerCase(),
    };
    delete submissionData.passwordConfirmation; // Remove password confirmation field from submission

    try {
      setLoading(true);
      const response = await httpClient.post(
        '/api/members/register',
        submissionData,
      );
      const addUserResult = response.data.addUserResult;

      if (onFormSubmit) {
        onFormSubmit(response.data);
      }

      notifications.show({
        title: 'Success',
        message: 'Physician registered successfully',
        color: 'green',
        position: 'bottom-center',
      });

      // Navigate to confirmation page
      navigate('/insurance/register/confirmation', {
        state: { addUserResult }, // Pass the result as state
      });
    } catch (error) {
      setLoading(false);
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit physician form',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData, reset]);

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Login Information</Title>
          <Text mt="xs" size="sm">
            This information will be used to create your account. Please choose
            a password of at least "Good" strength.
          </Text>
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                label="Email"
                required
                disabled={loading}
                {...register('email', { required: true })}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <PasswordInput
                label="Password"
                required
                disabled={loading}
                visible={showPassword} // Toggle visibility
                maxLength={50}
                onVisibilityChange={setShowPassword} // Toggle function for eye icon
                {...register('password', { required: true })}
              />
              <PasswordStrengthBar
                password={password}
                onChangeScore={(score) => setPasswordStrengthScore(score)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <PasswordInput
                label="Confirm Password"
                required
                disabled={loading}
                visible={showPassword} // Toggle visibility
                onVisibilityChange={setShowPassword} // Toggle function for eye icon
                {...register('passwordConfirmation', {
                  required: true,
                  validate: (value) =>
                    value === password || 'Passwords do not match',
                })}
                error={
                  errors.passwordConfirmation ? 'Passwords do not match' : null
                }
              />
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Personal Information</Title>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                required
                disabled={loading}
                {...register('firstname', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                required
                disabled={loading}
                {...register('lastname', { required: true })}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Medical License Number"
                required
                disabled={loading}
                {...register('licensenumber', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="licensestate"
                control={control}
                rules={{ required: 'State is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Medical License State"
                    data={STATES_ARRAY}
                    clearable
                    searchable
                    required
                    disabled={loading}
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <CustomDateInput
                control={control}
                name="licenseexpiration"
                label="License Expiration Date"
                required
                disabled={loading}
              />
            </Grid.Col>

            <Grid.Col span={6}>
              <CustomDateInput
                control={control}
                name="birthday"
                label="Date of Birth"
                required
                disabled={loading}
                preventFutureDates
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                label="NPI"
                required
                disabled={loading}
                {...register('npi', { required: true })}
                maxLength={10}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                }}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Questions or Comments</Title>
          <Text mt="xs" size="sm">
            If you have any questions, comments, concerns about your
            registration or WeCare IPA, or simply want to share your thoughts,
            feel free to leave us a message below.
          </Text>
          <Textarea
            placeholder="Please write any questions or comments you have here..."
            minRows={4}
            disabled={loading}
            {...register('message')}
          />
        </Paper>

        <Button type="submit" mt="md" disabled={loading}>
          {loading ? (
            <>
              <Loader color="blue" size="xs" mr="xs" />
              Loading...
            </>
          ) : (
            'Sign Up'
          )}
        </Button>
      </form>
    </Container>
  );
};

export default IpaMemberSignup;
