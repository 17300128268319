export const planSelectionOnboardingSteps = [
  {
    element: '#graph-slider',
    intro:
      'Toggle between the different graph types to visualize your plan information in various formats',
  },
  {
    element: '#option-buttons',
    intro:
      'Choose which plans/details you want to view side by side, including in network and out of network benefits for each plan',
  },
  {
    element: '#search-bar',
    intro: "Use the search bar to find the benefits you're interested in",
  },
  {
    element: '#live-plans-date',
    intro: 'Our plans will go live in Q1 2025',
    position: 'left',
  },
];
