import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Title,
  TextInput,
  Button,
  Container,
  Grid,
  PasswordInput,
  Loader,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useParams } from 'react-router-dom';
import useHttpClient from '../hooks/useHttpClient';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useNavigate } from 'react-router-dom';

interface OrganizationFormProps {
  initialData?: any;
}

const OrganizationForm: React.FC<OrganizationFormProps> = ({ initialData }) => {
  const { enrollmentcode } = useParams<{ enrollmentcode: string }>();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: initialData,
  });

  const [loading, setLoading] = useState(false);
  const password = watch('password');
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const httpClient = useHttpClient();

  // Fetch employee data from the database using the enrollmentcode
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await httpClient.get(
          `/api/organization/user/${enrollmentcode}`,
        );
        const data = await response.data;
        reset(data);
      } catch (error) {
        console.error('Error fetching employee data', error);
        notifications.show({
          title: 'Error',
          message: 'Failed to load employee data',
          color: 'red',
          position: 'bottom-center',
        });
      } finally {
        setLoading(false);
      }
    };

    if (enrollmentcode) {
      fetchData();
    }
  }, [enrollmentcode, reset]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    if (passwordStrengthScore < 3) {
      notifications.show({
        title: 'Weak Password',
        message:
          'Please choose a stronger password (at least "Good" strength).',
        color: 'red',
        position: 'bottom-center',
      });
      setLoading(false);
      return;
    }

    const submissionData = {
      ...data,
      passwordConfirmation: undefined,
      email: data.email.toLowerCase(),
      type: 'employee',
    };

    try {
      // Send a PUT request to update the form data
      const response = await httpClient.put(
        `/api/organization/user/${enrollmentcode}`,
        submissionData, // Axios will automatically stringify the data
      );

      if (response.status === 200) {
        // Assuming the API returns the updated data as JSON
        const result = response.data;

        notifications.show({
          title: 'Success',
          message: 'Successfully registered',
          color: 'green',
          position: 'bottom-center',
        });
        navigate(`/login`);
      } else {
        throw new Error('Failed to update form');
      }
    } catch (error) {
      console.error('Error updating form', error);

      notifications.show({
        title: 'Error',
        message: 'Failed to update form',
        color: 'red',
        position: 'bottom-center',
      });
      setLoading(false);
    }
  };

  return (
    <Container>
      <Title order={2} style={{ textAlign: 'center', marginBottom: '2rem' }}>
        Organization User Registration
      </Title>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="First Name"
              required
              {...register('firstname', { required: true })}
              disabled={loading} // Disable while loading
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Last Name"
              required
              {...register('lastname', { required: true })}
              disabled={loading} // Disable while loading
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label="Email"
              readOnly
              required
              {...register('email', {
                required: 'Email is required',
              })}
              disabled={true}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <PasswordInput
              label="Password"
              type="password"
              onVisibilityChange={setShowPassword}
              maxLength={50}
              required
              {...register('password')}
              disabled={loading} // Disable while loading
            />
            <PasswordStrengthBar
              password={password || ''}
              onChangeScore={(score) => setPasswordStrengthScore(score)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <PasswordInput
              label="Confirm Password"
              required
              visible={showPassword}
              onVisibilityChange={setShowPassword}
              disabled={loading}
              {...register('passwordConfirmation', {
                required: true,
                validate: (value) =>
                  value === password || 'Passwords do not match',
              })}
              error={
                errors.passwordConfirmation ? 'Passwords do not match' : null
              }
            />
          </Grid.Col>
        </Grid>
        <Button type="submit" mt="md" disabled={loading}>
          {loading ? (
            <>
              <Loader color="blue" size="xs" mr="xs" />
              Loading...
            </>
          ) : (
            'Register'
          )}
        </Button>
      </form>
    </Container>
  );
};

export default OrganizationForm;
