import React from 'react';
import { Modal, Text, Stack, Title } from '@mantine/core';

interface ThankYouModalProps {
  opened: boolean;
  onClose: () => void;
}

const ThankYouModal: React.FC<ThankYouModalProps> = ({ opened, onClose }) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      styles={{
        header: {
          backgroundColor: '#ebe9fa', // Set your custom background color here
        },
        body: {
          backgroundColor: '#ebe9fa', // Set your custom background color here
        },
        content: {
          backgroundColor: '#ebe9fa', // Set your custom background color here
        },
      }}
    >
      <Stack>
        <strong>
          <Title order={1} style={{ color: '#3f29cd' }}>
            Thank you for selecting your plan
          </Title>
        </strong>

        <Text size="md" fw={500}>
          Once the Ryze plans are live, our team will email you to complete your
          sign up.
        </Text>
      </Stack>
    </Modal>
  );
};

export default ThankYouModal;
