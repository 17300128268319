/* eslint-disable no-throw-literal */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Tabs,
  Grid,
  Button,
  Group,
  Title,
  Container,
  Text,
} from '@mantine/core';
import PersonalInformationTab from './PersonalInformationTab';
import FamilyMemberManagementTab, {
  familyMgmtValidation,
} from './FamilyManagementTab';
import HomeIcon from '../HomeIcon';
import LogoutIcon from '../LogoutIcon';
import DarkModeToggle from '../DarkModeToggle';
import {
  InsuranceMemberProvider,
  useInsuranceMemberContext,
} from './InsuranceMemberContext';
import { notifications } from '@mantine/notifications';
import PlanSelectionTab from './PlanSelectionTab';
import CompleteRegistrationModal from './CompleteMemberRegistration';
import {
  EmployeeRegistrationStepper,
  StepperProvider,
  useStepper,
  NextStepButton,
  PrevStepButton,
  ButtonContainer,
  CompleteStepButton,
} from './RegistrationSteppers';
import { useLocation } from 'react-router-dom';
import { subMenuList } from '../menu.config';
import { ModalsProvider, modals } from '@mantine/modals';
import { IconRosetteDiscountCheckFilled } from '@tabler/icons-react';
import PrioritiesTab from './PrioritiesTab';
import ThankYouModal from './ThankYouModal';

const InsuranceEmployeePortal: React.FC = () => {
  const location = useLocation();
  const currentTab = location.hash.substring(1) || 'personal-info';

  const { member, refreshMemberData } = useInsuranceMemberContext();
  const [modalOpened, setModalOpened] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(currentTab);
  const [thankYouModalOpened, setThankYouModalOpened] = useState(false);

  const { enabled, markStepAsDone, activeStep, subMenu } = useStepper();
  const [isFamilyFieldsComplete, setIsFamilyFieldsComplete] = useState(false); //temp true until implement check
  const [isPrioritiesComplete, setIsPrioritiesComplete] = useState(true);
  const [isPlanSelectionComplete, setIsPlanSelectionComplete] = useState(true);

  /**
   * Checks if all required personal information fields are complete.
   *
   * @returns `true` if all fields are complete, otherwise `false`.
   */
  const checkFieldsComplete = async () => {
    const requiredPersonalInfoFields = [
      'firstname',
      'lastname',
      'birthdate',
      'email',
      'street1',
      'city',
      'state',
      'zip',
      'county',
    ];
    const incompleteFields: string[] = [];
    const completeFields: string[] = [];

    const isPersonalInfoComplete = requiredPersonalInfoFields.every((field) => {
      const isFieldComplete =
        member &&
        member[field as keyof typeof member] &&
        member[field as keyof typeof member] !== '';
      if (isFieldComplete) {
        completeFields.push(field);
      } else {
        incompleteFields.push(field);
      }
      return isFieldComplete;
    });

    // Log both the complete and incomplete fields for debugging purposes
    console.log('Complete Fields:', completeFields);
    console.log('Incomplete Fields:', incompleteFields);

    const validateForm = (infoComplete) => {
      if (infoComplete) {
        markStepAsDone(activeTab);
        return true;
      } else {
        markStepAsDone(activeTab, false);
        return false;
      }
    };

    if (activeTab === 'personal-info') {
      return validateForm(isPersonalInfoComplete);
    }
    if (activeTab === 'priorities') {
      return validateForm(isPrioritiesComplete);
    }
    if (activeTab === 'plan-selection') {
      return validateForm(isPlanSelectionComplete);
    }
    if (activeTab === 'family-member-management') {
      return validateForm(isFamilyFieldsComplete);
    }
    return true;
  };

  const handleOpenModal = () => {
    setModalOpened(true);
  };

  const validationCallback = async () => {
    await refreshMemberData();

    if (activeTab === 'family-member-management') {
      const isFamilyConfirmed = await familyMgmtValidation();
      if (isFamilyConfirmed) {
        markStepAsDone(activeTab);
      } else {
        markStepAsDone(activeTab, false);
      }
      return isFamilyConfirmed;
    }

    return await checkFieldsComplete();
  };

  useEffect(() => {
    setActiveTab(currentTab);
    if (member) {
      try {
        checkFieldsComplete();
      } catch (e) {}
    }
  }, [currentTab, member]);

  // do not stop steppers flow if ryze user
  const shouldStopFlow =
    activeStep === 2 && !member?.email?.match(/\@ryzehealth.com$/);

  return (
    <>
      <ButtonContainer>
        <PrevStepButton enabled={enabled} />
        <EmployeeRegistrationStepper />
        {shouldStopFlow && (
          <CompleteStepButton
            enabled={true}
            onClick={() => setThankYouModalOpened(true)}
          />
        )}
        {activeStep === subMenu.length - 1 && (
          <CompleteStepButton
            enabled={enabled}
            onClick={() => {
              handleOpenModal();
            }}
          />
        )}
        {!shouldStopFlow && activeStep !== subMenu.length - 1 && (
          <ModalsProvider>
            <NextStepButton
              enabled={enabled && activeTab !== 'priorities'}
              validationCallback={validationCallback}
            />
          </ModalsProvider>
        )}
      </ButtonContainer>

      <Grid justify="space-between">
        <Grid.Col span="auto">
          <HomeIcon />
          <LogoutIcon />
        </Grid.Col>
        <Grid.Col span="auto">
          <DarkModeToggle />
        </Grid.Col>
      </Grid>

      {/* <Title order={1} style={{ textAlign: 'center' }}>
        Employee Portal
      </Title> */}

      <ThankYouModal
        opened={thankYouModalOpened}
        onClose={() => setThankYouModalOpened(false)}
      />

      <CompleteRegistrationModal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
      />

      <Tabs defaultValue="personal-info" value={activeTab} keepMounted={false}>
        <Tabs.Panel value="personal-info">
          {member && (
            <PersonalInformationTab
              onFormSubmit={() => {
                refreshMemberData();
              }}
            />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="family-member-management">
          {member && (
            <FamilyMemberManagementTab
              isEmployeePortal
              onFieldsCompleteChange={setIsFamilyFieldsComplete}
            />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="priorities">
          {member && (
            <PrioritiesTab onFieldsCompleteChange={setIsPrioritiesComplete} />
          )}
        </Tabs.Panel>
        {/* <Tabs.Panel value="plan-selection">
          {member && (
            <PlanSelectionTab
              onFieldsCompleteChange={setIsPlanSelectionComplete}
            />
          )}
        </Tabs.Panel> */}
      </Tabs>
    </>
  );
};

const InsuranceEmployeePortalWrapped: React.FC = () => {
  const subMenu = subMenuList.employee.menu;
  return (
    <InsuranceMemberProvider>
      <StepperProvider totalSteps={4} subMenu={subMenu}>
        <Container size="xl" style={{ marginTop: '2rem' }}>
          <InsuranceEmployeePortal />
        </Container>
      </StepperProvider>
    </InsuranceMemberProvider>
  );
};

export default InsuranceEmployeePortalWrapped;
