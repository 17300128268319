import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Grid,
  Paper,
  Title,
  Select,
  Flex,
  Loader,
  Text,
  LoadingOverlay,
  Input,
  InputBase,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { STATES_ARRAY } from '../../types/members.types';
import { useInsuranceMemberContext } from './InsuranceMemberContext';
import useHttpClient from '../hooks/useHttpClient';
import { useStepper } from './RegistrationSteppers';
import { loadScript } from '../../helpers/loadScript';
import CustomDateInput from '../CustomDateInput';
import { IMaskInput } from 'react-imask';
declare global {
  interface Window {
    google: any;
  }
}

const PersonalInformationTab = ({
  onFormSubmit,
}: {
  onFormSubmit?: (data: any) => void;
}) => {
  const { subscriberMemberId } = useInsuranceMemberContext();
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { isDirty },
  } = useForm();
  const [isEditing, setIsEditing] = useState(false);
  const httpClient = useHttpClient();
  const { setEnabled, markStepAsDone } = useStepper();
  const [loading, setLoading] = useState(true);

  const addressRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let autocomplete: google.maps.places.Autocomplete | null = null;

    const handlePlaceSelect = async () => {
      if (!autocomplete) return;

      const place = autocomplete.getPlace();

      // Reset fields before handling the selected place
      setValue('address', '');
      setValue('street1', '');
      setValue('street2', '');
      setValue('city', '');
      setValue('state', '');
      setValue('zip', '');
      setValue('county', '');

      if (!place.address_components) {
        alert('Please select a complete address with a street number.');
        return;
      }

      let hasStreetNumber = false;
      place.address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          hasStreetNumber = true;
        }
      });

      if (!hasStreetNumber) {
        alert('Please select an address that includes a street number.');
        return;
      }

      let streetNumber = '';
      let route = '';
      let street2 = '';
      let city = '';
      let state = '';
      let zipCode = '';
      let county = '';

      place.address_components.forEach((component) => {
        const types = component.types;
        if (types.includes('street_number')) streetNumber = component.long_name;
        if (types.includes('route')) route = component.long_name;
        if (types.includes('subpremise')) street2 = component.long_name;
        if (types.includes('locality')) city = component.long_name;
        else if (types.includes('sublocality')) city = component.long_name;
        if (types.includes('administrative_area_level_1'))
          state = component.short_name;
        if (types.includes('postal_code')) zipCode = component.long_name;
        if (types.includes('administrative_area_level_2'))
          county = component.long_name.replace(' County', '');
      });

      const street1Value = `${streetNumber} ${route}`.trim();

      // Update the form fields and manually trigger validation to mark the form dirty
      setValue('street1', street1Value, { shouldDirty: true });
      setValue('street2', street2, { shouldDirty: true });
      setValue('city', city, { shouldDirty: true });
      setValue('state', state, { shouldDirty: true });
      setValue('zip', zipCode, { shouldDirty: true });
      setValue('county', county, { shouldDirty: true });

      await trigger(); // Manually trigger form validation
    };

    const initAutocomplete = () => {
      if (addressRef.current && window.google) {
        autocomplete = new window.google.maps.places.Autocomplete(
          addressRef.current,
          {
            types: ['address'],
            componentRestrictions: { country: 'us' },
          },
        );
        autocomplete.addListener('place_changed', handlePlaceSelect);
      }
    };

    const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    loadScript(googleMapsScriptUrl)
      .then(initAutocomplete)
      .catch((err) => console.error('Failed to load Google Maps script:', err));

    return () => {
      if (autocomplete) {
        google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [setValue, trigger]);

  // Fetch member data
  const fetchMemberData = async () => {
    try {
      const response = await httpClient.get(`/api/subscriber/`);
      reset(response.data);
    } catch (error) {
      console.error('Error fetching member data:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to load member data',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      delete data.address;
      await httpClient.put(`/api/subscriber/${subscriberMemberId}`, data);
      markStepAsDone('personal-info');
      notifications.show({
        title: 'Success',
        message: 'Personal information updated successfully',
        color: 'green',
        position: 'bottom-center',
      });
      setIsEditing(false);
      fetchMemberData();
      if (onFormSubmit) onFormSubmit(data);
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update personal information',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  useMemo(() => {
    if (subscriberMemberId) fetchMemberData();
  }, [subscriberMemberId]);

  return (
    <Container>
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />

      <Title order={1} style={{ textAlign: 'center' }}>
        Personal Information
      </Title>
      <br />
      <Title order={5}>
        Please complete the information below before preceding
      </Title>

      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
      >
        <Paper withBorder shadow="xs" p="md">
          {/* Previous personal information fields remain unchanged */}
          <Grid justify="space-between" align="center">
            <Grid.Col span={11}>
              <Title order={3}>Personal Information</Title>
            </Grid.Col>
          </Grid>

          {/* Personal Information Fields */}
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                {...register('firstname', { required: true })}
                required
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                {...register('lastname', { required: true })}
                required
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="sex"
                control={control}
                rules={{ required: 'Sex is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Sex at Birth"
                    data={[
                      { value: 'M', label: 'M' },
                      { value: 'F', label: 'F' },
                    ]}
                    clearable
                    required
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <CustomDateInput
                control={control}
                name="birthdate"
                label="Date of Birth"
                preventFutureDates
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label={'Email\u2060'}
                {...register('email', { required: true })}
                disabled
                required
              />
            </Grid.Col>
            {/* <Grid.Col span={6}>
              <Controller
                name="ssn"
                control={control}
                rules={{
                  required: 'SSN is required',
                  pattern: {
                    value: /^\d{3}-\d{2}-\d{4}$/,
                    message: 'Invalid SSN format',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputBase
                      label="SSN"
                      placeholder="XXX-XX-XXXX"
                      required
                      component={IMaskInput}
                      mask="000-00-0000"
                      {...field}
                    />
                    {error && (
                      <span style={{ color: 'red', fontSize: '0.8em' }}>
                        {error.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid.Col> */}
          </Grid>
        </Paper>
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>{`\u0410ddress Information`}</Title>
          <TextInput
            label={
              '\u0410ddress - Start typing your address below and pick one from the dropdown'
            }
            ref={addressRef}
            placeholder="Start by typing here"
          />
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label={'\u0405treet 1'}
                {...register('street1')}
                disabled
                required
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label={'\u0405treet 2'} {...register('street2')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="City" {...register('city')} disabled required />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="State"
                    data={STATES_ARRAY}
                    disabled
                    required
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="ZIP"
                {...register('zip')}
                disabled
                required
                maxLength={5}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="County"
                {...register('county')}
                disabled
                required
              />
            </Grid.Col>
          </Grid>
        </Paper>
        <Flex justify="flex-end" mt="md">
          <Button type="submit" disabled={!isDirty || loading}>
            {loading ? (
              <Loader size="xs" color="blue" mr="xs" />
            ) : (
              'Save Changes'
            )}
          </Button>
        </Flex>
      </form>
    </Container>
  );
};

export default PersonalInformationTab;
