import React, { useState } from 'react';
import {
  TextInput,
  Button,
  Paper,
  Container,
  Title,
  Center,
  Text,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';

export default function PasswordResetRequest() {
  const [username, setUsername] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const httpClient = useHttpClient();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (username) {
      try {
        await httpClient.post('/api/auth/request-password-reset', {
          username,
        });
        notifications.show({
          title: 'Success',
          message: 'Password reset instructions have been sent to your email.',
          color: 'green',
          position: 'top-center',
        });
      } catch (error) {
        setErrorMessage(error.response.data);
        notifications.show({
          message: `${error.response.data}`,
          color: 'red',
          position: 'top-center',
        });
        setTimeout(() => {
          setErrorMessage('');
        }, 5000);
      }
    } else {
      alert('Please enter your email');
    }
  };

  return (
    <Container size={420} mt={100} style={{ background: 'none' }}>
      <Paper withBorder shadow="md" p={30} radius="md" mt="lg">
        <form onSubmit={handleSubmit}>
          <Center mb="lg">
            <Title order={2}>Reset Password</Title>
          </Center>
          <TextInput
            label="Email"
            placeholder="Enter your email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            mb="md"
          />
          {errorMessage && (
            <Text color="red" ta="center" mb="md">
              {errorMessage}
            </Text>
          )}
          <Center mt="md">
            <Button type="submit">Send Reset Instructions</Button>
          </Center>
        </form>
      </Paper>
    </Container>
  );
}
