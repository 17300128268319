import React from 'react';
import { Controller } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { InputBase } from '@mantine/core';
import { parse, isValid, format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

interface CustomDateInputProps {
  control: any; // This should be the control from useForm
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  preventFutureDates?: boolean;
}

const CustomDateInput: React.FC<CustomDateInputProps> = ({
  control,
  name,
  label = 'Date of Birth',
  required = true,
  disabled = false,
  preventFutureDates = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
        validate: {
          customRule: (value: string) => {
            if (!required) return true;

            // Validate only if we have a complete date (8 numbers for MM/DD/YYYY)
            if (value && value.length === 10) {
              const date = parse(value, 'MM/dd/yyyy', new Date());
              if (!isValid(date)) {
                return 'Please enter a valid date following the format: MM/DD/YYYY.';
              }
              if (preventFutureDates && date > new Date()) {
                return "Please don't enter a future date.";
              }
            }
            return true;
          },
        },
      }}
      render={({ field, fieldState: { error } }) => {
        const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
          // Clear the field if Backspace or Delete is pressed
          if (evt.key === 'Backspace' || evt.key === 'Delete') {
            field.onChange(''); // Clear the value
          }
        };

        const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
          const inputValue = evt.target.value;

          // Allow free typing, but only format when there are exactly 8 numeric characters
          const cleanedValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
          if (cleanedValue.length === 8) {
            const formattedDate = `${cleanedValue.slice(0, 2)}/${cleanedValue.slice(2, 4)}/${cleanedValue.slice(4, 8)}`;
            field.onChange(formattedDate); // Save the formatted date
          } else {
            field.onChange(inputValue); // Save raw input for incomplete dates
          }
        };

        const formatInitialValue = (value: string) => {
          // Format initial ISO date from the database into MM/DD/YYYY
          if (value && typeof value === 'string' && value.includes('T')) {
            try {
              const zonedDate = toZonedTime(value, 'UTC'); // Treat ISO date as UTC
              return format(zonedDate, 'MM/dd/yyyy');
            } catch (e) {
              console.error('Error formatting date:', e);
            }
          }
          return value; // Return as-is if not a valid ISO date
        };

        const formattedValue = formatInitialValue(field.value);

        return (
          <>
            <InputBase
              placeholder="MM/DD/YYYY"
              value={formattedValue}
              label={label}
              disabled={disabled}
              required={required}
              component={IMaskInput}
              mask="00/00/0000"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
            {error && (
              <span style={{ color: 'red', fontSize: '0.8em' }}>
                {error.message}
              </span>
            )}
          </>
        );
      }}
    />
  );
};

export default CustomDateInput;
